body{
    margin: 0px;
    font-family: nunito, sans-serif;
}
span.Wac{
    color: #00a884;
}
span.blc {
    color: #000;
}
.MuiDrawer-paper {
    background-image: linear-gradient(0deg, #FFFFFF 0%, #e7fce3 100%);
}
.webSection1TitleE {
    color: #f6b7ab;
}
.webSection1Image {
    width:100%;
    object-fit:contain;
}
@media (min-width: 0px) and (max-width: 599.95px) {
    .webSection1Image {
        width:100%;
        object-fit:contain;
    }
    .webSection1Image1 {
        width:50%;
        object-fit:contain;
    }
}
@media (min-width: 600px) and (max-width: 899.95px) {
    .webSection1Image1 {
        width:250%;
        object-fit:contain;
    }
    .webSection1Image2 {
        width:766%;
        object-fit:contain;
    }
}
img.shap {
    position: absolute;
    z-index: -1;
}
img.shap.shap1 {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -70px;
}
img.shap.shap2 {
    bottom: 0;
    right: 50px;
}
img.shap.shap3 {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -70px;
}
img.shap.shap4 {
    bottom: 0;
    left: 50px;
}
img.shapImage {
    width:572px;
    height: 100%;
}
.webBlasterWait input#outlined-basic {
    padding: 10px;
}
.webBlasterWait .Mui-focused fieldset {
    border-color: #00a884 !important;
}
@media (min-width: 0px) and (max-width: 899.95px) {
    img.shap.shap1 {
        width: 70%;
    }
    img.shap.shap2 {
        bottom: 30px;
    }
    img.shapImage {
        width:300px;
        height: 262px;
    }
    img.shap.shap3 {
        width: 70%;
        right: -16px;
    }
    
    img.shap.shap4 {
        bottom: 30px;
        width:85%
    }
}
.wabSTIcon {
    color: #00a884;
}
.wabwaitlist fieldset {
    border-color: #00a884;
}
.wabwaitlist input#fullWidth {
    background-color: #fff;
}
.wabwaitlist .Mui-focused fieldset {
    border-color: #00a884 !important;
}
.MuiSnackbarContent-root{
    background-color:#00a884 !important;
    font-family:nunito, sans-serif !important; 
}
ul.MuiList-root.MuiList-padding.css-h4y409-MuiList-root {
    margin-top: 48px;
}
.wablastersQc .MuiSelect-select {
    padding: 10px;
    background: #d9d9d93d;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #00a884 !important;
}
.wablastersUserModel input {
    padding: 10px;
}
.wablastersUserModel fieldset,div#wablasterselect fieldset {
    border-color: #000;
}
div#wa-select {
    padding: 10px 9px;
}
div#wa-select .wabCountry {
    display: none;
}
.wabLoader {
    width: 25px !important;
    height: 25px !important;
    padding: 0px 27px;
}
.wabLoader svg {
    color: #fff;
}